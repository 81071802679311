.list {
    background-color: transparent;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: calc(80vh - 100px);
}

.picto {
    height: 3.5rem;
    margin-right: 15px;
  }
  
  .title {
    display: flex;
    align-items: center;
    /* font-size: 2rem; */
    margin-bottom: 15px;
  }

  .placeholder {
    margin-top: 100px;
  }