.menuItem {
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

.itemContainer {
}

.pictoContainer {
}

.picto {
  height: 2.5rem;
}

.libelle {
  font-size: 1rem;
  font-family: OpenSans-Bold, Arial, Helvetica, sans-serif;
}

.disable {
  /* filter: blur(2px); */
  cursor: not-allowed;
  color: grey;
}

.hovered {
  color: #95c043;
}