.close {
    display: flex;
    justify-content: flex-end;
}

.level2Container {
    margin-left: 30px;
}

.picto {
    width: 20px;
}

.category {
    margin-bottom: 20px;
}

.title {
    font-size: 2rem;
    margin-bottom: 20px;
}

.infoContainer {
    font-family: OpenSans, Arial, Helvetica, sans-serif;
    font-size: 1.3rem;
}

.info {
    display: flex;
    margin-bottom: 15px;
}

.subtitle {
    text-decoration: underline;
    margin-right: 5px;
    white-space: nowrap;
}

.commentsContainer {

}

.comments {

}

.comment {
    margin-bottom: 10px;
}

.commentHeader {
    display: flex;
}

.commentAuthor {
    margin-right: 10px;
}

.commentFormWrapper {
    margin-bottom: 20px;
    margin-top: 40px;
}

.commentDate {

}

.commentMessage {

}

.commentInput {

}

.commentInputTitle {

}

.input {

}

.textArea {

}

.button {
    
}