.mainContainer {
  margin-top: 5vh;
  /* height: calc(100vh - 7rem - 5vh - 5vh); */
  margin-left: 7%;
  width: 86%;
}

.pushable {
  height: calc(100% - 7rem);
  box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.16);
}