.header {
  display: flex;
  justify-content: space-around;
  background-color: white;
  /* margin-bottom: 50px; */
  height: 7rem;
  align-items: center;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.logo {
  height: 5rem;
}

.logoContainer {
  cursor: pointer;
}
