.filterBtn, .selected:hover {
  font-family: OpenSans-SemiBold, Arial, Helvetica, sans-serif !important;
  font-size: 1.4rem;
  text-transform: uppercase !important;
  border: 1px solid transparent;
  color: #0A405D;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  line-height: 1.4rem;
  text-align: center;
  box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.11);
  display: flex;
}

.filterBtn:hover, .selected {
  background-color: #0A405D;
  color: white;
}

.secondary {
  border: 1px solid black;
  background-color: white;
  color: black;
}

.inverted {
  background-color: white;
  color: #91c800;
  border: 1px solid #91c800;
}

.inverted:hover {
  background-color:  #91c800;
  color: white;
}

.warning {
  background-color: #C53211;
  color: white;
  border: 1px solid #C53211;
}

.warning:hover {
  background-color: white;
  color: #C53211;
  border: #C53211;
}


.disabled, .disabled:hover {
  border: 1px solid transparent;
  background-color: #d3d3d3;
  color: white;
  cursor: not-allowed;
}

