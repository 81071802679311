
.logout {
  z-index: 1;
}

.accountContainer {
  display: flex;
  align-items: center;
}

.accountPictoContainer {
  display: inline-block;
}

.accountPicto, .logoutPicto {
  height: 2rem;
}

.accountLibelle, .logoutLibelle {
  font-size: 1.2rem;
  font-family: OpenSans, Arial, Helvetica, sans-serif;
  color: #0a405d;
  display: inline-block;
  margin-left: 10px;
}

.logoutContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.56);
}

.logoutContainer::before, .logoutContainer::after {
  content: '';
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  background: white;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.56);
  position: absolute;
  top: -5px;
  left: 3px;
  z-index: -1;
}

.logoutContainer::after {
  box-shadow: unset;
  z-index: 0;
}

.logoutPictoContainer {
    display: flex;
  cursor: pointer;
}
