.table {
  height: 100%;
  min-height: 20vh;
  overflow-y: auto;
  width: 100%;
  position: relative;
}

.errorWrapper, .noResultWrapper {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.semanticLoader {
  height: 100px !important;
  position: relative !important;
}
