.ecosystem {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.wrapper {
    display : flex;
    flex-direction: column;
}

.header {
    margin-top: 100px;
    margin-bottom: 40px;
}

.cards {
    width: 150px;
}

.top2 {
    width: 150px;
}

.top {
    width: 130px;
}