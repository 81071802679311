.row {
  display: flex;
  /* flex-direction: column; */
}

.nonCompliant {
  background-color: #C53211 !important;
  color: white !important;
  /* min-width: 3em !important;
  min-height: 3em !important; */
  min-width: 10.5em !important;
  min-height: 4em !important;
  padding: 1em !important;
  line-height: 3.5em !important;
  text-align: center !important;
  border-radius: 500rem;
}

@media screen and (max-width: 1290px) {
  .nonCompliant {
    line-height: 2.5em !important;
  }
}

.compliant {
  background-color: #91c800 !important;
  color: white !important;
  text-align: center !important;
  border-radius: 500rem;
  min-width: 10.5em !important;
  min-height: 4em !important;
  padding: 1em !important;
  line-height: 3em !important;
}