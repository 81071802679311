.list {
    display: flex;
    justify-content: center;
  }
  

.simpleCard {
    /* display: flex; */
    /* border: 1px solid black; */
    width: 100%;
    background-color: white;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    padding: 10px;
}

.cardLabel {
    font-size: 1.5rem;
}

.cardValue {
    font-size: 1.2rem;
}


.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
}

.element {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    flex-grow: 1;
}

.tableContainer {
    background-color: white;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    padding: 1rem;
    padding-bottom: 2rem;
    overflow-y: auto;
    max-height: 100vh;
}

.placeholder {
    margin-top: 100px;
}

.headerContainer {
    display: flex;
    justify-content: space-between;
}

.downloadChoiceContainer {
    display: flex;
    justify-content: space-between;
    font-family: OpenSans-SemiBold, Arial, Helvetica, sans-serif !important;
    font-size: 1.4rem;
    text-transform: uppercase !important;
    border: 1px solid transparent;
    background-color: #95c043;
    color: white;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 10px;
    line-height: 1.4rem;
    text-align: center;
    box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.11);
    position: relative;

}

.choices {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.11);
    border: 1px solid black;
    border-bottom: none;
    text-align: center;
}

.informations {
    display:  flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 3% 0 2% 0;
}

.downloadContainer {
    display: flex;
    flex-direction: row-reverse;
}

