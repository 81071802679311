.title {
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  line-height: 33px;
  color: rgba(10, 64, 93, 0.78);
}

.refreshAudits {
  margin-top: 3%;
  display: block;
}

.refreshAudits a {
  text-decoration: none;
  font-size: 1.5em;
  margin-left: 5px;
  margin-top: -4%;
}


.cardColorExpert {
  background: #95BF41 !important;

}

.cardColorMembers {
  background: #608A0C !important;
}

.cardColorRisk {
  background: #F2CB05 !important;
}

.cardColorCritic {
  background: #F28705 !important;
}

.cardColorNotValid {
  background: rgba(255, 0, 0, 0.82) !important;
}

.textCardColorPourcent {
  font-size: 30px;
}

.textCardColorActor {
  font-size: 15px;
}

.miniCard {
  display: inline-block;
}

.miniCardColor {
  display: inline-block;
  margin: 20px 20px 0 0;
  width: 17px;
  height: 18px;

}

.miniCardText {
  font-size: 18px;
  font-weight: 800;
  margin: 20px 20px 0 0;
}

.statusButton {
  width: 120px;
  height: 38px;
  border-radius: 15px !important;
  color: #ffffff !important;
  text-align: center;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 21px !important;
}

.searchPicto {
  height: 15px;
} 

.inputContainer {
  display: flex;
  justify-content: center;
}

.input {
  border-radius: 150px;
  border: 2px solid #0a405d;
  width: calc(25% - 5px);
  background: transparent;
}

.filterDiv {    
  margin: 20px 0;
}

.margeElementTableBody {
  padding-left: 2.7% !important;
}

.margeElementTableBodyNotConform {
  padding-left: 3.5% !important;
} 

.ui.label:first-child {
  margin-left: -3em;
} 