.datePicker {
    position: absolute;
    top: 100%;
    left: 100%;
    background-color: #efefef;
    transform: translateX(-45%);
    border-bottom-right-radius: 15%;
    border-bottom-left-radius: 15%;
}

.date-picker-container {
    position: relative;
}