.form {

}

.formContainer {

}

.formLine {
  width: 80%;
  margin-bottom: 20px;
  margin-left: 10%;
}

.inputDocumentWrapper {
  border: 1px dashed;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 1.7rem;
  margin-bottom: 30px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

}

.result {

}

.inputLabel {

}

.error {

}

.cancelActions {

}

.formActions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}

.navigationActions {
  display: flex;
  justify-content: space-between;
}

.backNavigation {
  margin-right: 10px;
}

.nextNavigation {

}

.previewImage {
  width: 100%;
}