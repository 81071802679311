.dateDisabled {
    width: 100%;
    height: 100%;
}

.icon {
    height: 30px;
    width: 30px;
    background: url("../../../../assets//pictos/productionspecificationsPictoBlue.png")
        center/36px 36px no-repeat;
    cursor: pointer;
}

.container{
    /* width:  40% !important; */
    padding: 30px;
    /* height: 60%; */

}