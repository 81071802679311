.list {
  margin-top: 20px;
}

.refresh {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  margin-left: 8px;
  width: auto;
}

.refreshIcon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.newGroup {
  width: 200px;
  padding: 1rem;
}

.actionWrapper {
  display: flex;
  flex-wrap: wrap;
}

.addContainer {
  display: flex;
  margin-right: 30px;
  align-items: center;
}

.pictoContainer {
  margin-right: 5px;
}

.addPicto {
  height: 25px;
}

.addTitle {
  font-size: 1.5rem;
}

.favoriteTitle {
  font-size: 2rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 10%;
  padding: 10px;
}