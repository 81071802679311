.loginForm {

}

.form {

}

.formContainer {

}

.inputBloc {
    margin-bottom: 50px;
}

.inputLabel {
    margin-bottom: 15px;
    display: block;
}

.submit {
    text-align: center;
    margin-bottom: 20px;
}

.linkContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10rem;
}

.remember {
    font-family: OpenSans;
}

.forgetContainer {
    margin-bottom: 30px;
}

.forgetLink {
    font-size: 1.4rem;
}
