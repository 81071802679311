.tile {
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageContainer {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.image {
  max-height: 25px;
  max-width: 25px;
  margin-top: 50%;
  transform: translate(0px, -50%);
}

.title {
  font-size: 1.6rem;
}


.textWrapper {

}

.tileContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

