.input {
    font-family: OpenSans, Arial, Helvetica, sans-serif;
    min-width: 5em !important;
    border-radius: 11px !important;
    border: solid 1px #000 !important;
    text-align: center !important;
    min-height: 1em !important;
    width: 100%;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  .list {

  }


  .inputBloc {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
  }

  .inputLabel {
    color: white;
  }

  .inputContainer {

  }

  .submit {

  }

  .tableWrapper {

  }

  .wrapper {
    max-width: 200px;
    margin: auto;
  }