
.alertList {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
}

.alertContainer {
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.criticalityPicto {
    width: 20px;
}

.criticalityContainer {
    margin-right: 10px;
    display: flex;
}

.dateAndFlowBlock {
    display : flex;
    align-items: center;
}

.description {
    font-family: OpenSans, Arial, Helvetica, sans-serif;
    font-size: 1.3rem;
}

.loader {
    background-color: white;
    height: 300px;
    text-align: center;
    align-self: center;
    display: block;
    justify-content: center;
    position: relative; 
    margin-top :30px;
    /* box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16); */
    height: 100%;
    /* overflow-y: auto; */
    /* width: calc((100vw - 300px) / 3); */
    /* padding-left: 30px; */
    width: 100%;
  }
  
  .semanticLoader {
    margin-top: 120px;
  }