.classicBtn {
  font-family: OpenSans-SemiBold, Arial, Helvetica, sans-serif !important;
  font-size: 1.4rem;
  text-transform: uppercase !important;
  border: 1px solid transparent;
  background-color: #95c043;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  line-height: 1.4rem;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.11);
}

.secondary {
  border: 1px solid black;
  background-color: white;
  color: black;
}

.inverted {
  background-color: white;
  color: #91c800;
  border: 1px solid #91c800;
}


.warning {
  background-color: #C53211;
  color: white;
  border: 1px solid #C53211;
}

.disabled, .disabled:hover {
  border: 1px solid transparent;
  background-color: #d3d3d3;
  color: white;
  cursor: not-allowed;
}


.brandScrollButton {
  border-radius: 5px;
  width: 20px;
  height: 100%;
  margin-left: 10px;
  box-shadow: 0 0px 3px 1px rgba(50, 50, 93, 0.11);
  border: 1px solid transparent;
  cursor: pointer;
}