html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

.specificationWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sidebarWrapper {
  background-color: lightgreen;
}

.headerWrapper {
  display: flex;
}

.resultCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-radius: 6px;
  margin-top: 5px;
  margin-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: -10px 0px 0px 0px rgba(149, 192, 67, 1), 0px 3px 10px gray;
  font-size: large;
  font-weight: bold;
}

.specDetails {
  display: flex;
  justify-content: space-evenly;
  font-size: smaller;
}

.header {
  display: flex;
}

.addButton {
  display: flex;
  justify-content: space-evenly;
}

.addIcon {
  width: 5%;
  height: 5%;
}

.addText {
  font-weight: 100;
}

.filterInput {
  width: 80%;
}

/*  toggle card */
.checked {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

/* Accordion styles */
.tabs {
  border-radius: 8px;
  overflow: hidden;
  margin-top: 5px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 10px;
  box-shadow: -10px 0px 0px 0px rgba(149, 192, 67, 1), 0px 3px 10px gray;
  font-size: large;
  font-weight: bold;
  padding-bottom: 10px;
}

.tab {
  width: 100%;
  overflow: hidden;
}

.cardIndicator{
  width: 98%;
}

.contentCard{
  display: flex;
  justify-content: space-between;
  padding: 1.2em;
  font-weight: bold;
  cursor: pointer;
}

/* .contentCard::after {
  content: "\276F";
  transform: rotate(90deg) !important;
  width: 1.2em !important;
  height: 1.2em !important;
  text-align: center;
  transition: all 0.35s !important;
} */

.rotateInit {
  transform: rotate(0);
  transition: all 0.2s linear; 
  margin: -10% 0 0 10%
}

.rotateUpdate {
  transform: rotate(180deg);
  transition: all 0.2s linear; 
  margin: -10% 0 0 10%
}

.indicator{
  margin-right: 5px;
  padding-top: 0.5%;
}

.indicatorContent{
  /* background-color: gray; */
  width: 80%;
  height: 5vh;
  font-size: 11px;
  font-weight: bold;
  padding-left: 10px;
  padding-top: 0.5%;
  word-wrap: break-word;
}

.indicatorButton{
  display: flex;
  justify-content: flex-start;
  padding-top: 0.5%;
}

.tabContent {
  max-height: 0;
  padding: 0 1em;
  transition: all 0.35s;
}

.checked:checked~.tabContent {
  max-height: 100vh;
  padding: 1em;
}

.checked:checked+.tabLabel::after {
  transform: rotateZ(-180deg);
}

.nonCompliant {
  background-color: #C53211 !important;
  color: white !important;
  padding: 5px;
  font-size: 13px;
  text-align: center;
  width: 136px;
  height: 29px;
  border-radius: 10px;
  opacity: 1;
}

.criticalMIN,
.criticalMAJ,
.criticalNEU {
  color: white !important;
  padding: 7px 17px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  width: 100px !important;
  height: 30px !important;
  border-radius: 10px !important;
  opacity: 1 !important;
  top: 362px;
  left: 100px;
}

.criticalMIN {
  background-color: #FBCA26 !important;
}

.criticalMAJ {
  background-color: #F28705 !important;
}

.criticalNEU {
  background-color: gray !important;
}

.compliant {
  background-color: rgba(149, 192, 67, 1) !important;
  color: white !important;
  padding: 5px;
  border-radius: 5px;
  font-size: 13px;
  text-align: center;
  width: 136px;
  height: 29px;
  border-radius: 10px;
  opacity: 1;
}

.noneCompliant {
  background-color: #97b6e3 !important;
  color: white !important;
  padding: 5px;
  font-size: 14px;
  text-align: center;
  width: 136px;
  height: 29px;
  border-radius: 10px;
  opacity: 1;
}

.titleCritere {
  /* padding: 0 0 0 65%; */
  margin-left: -73%;
  /* background-color: red;  */
  /* min-width: 50px; */
  word-wrap: break-word;
}

.titleCritere h4 {
  /* margin-left: -35%; */
  font-size: 10px;
}


/* The Modal (background) */
.hideModal {
  display: none;
  /* Hidden by default */
  position: absolute;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.showModal {
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  position: absolute;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}


.modalBackground {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
}

.modalShowingTrue {
  opacity: 1;
  pointer-events: showing;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
}

.modalInner {
  background: #fefefe;
  border: 1px solid #888;
  width: 900px;
  max-width: calc(100% - 60%);
  margin: auto;
  display: flex;
  flex-direction: row;
  text-align: right;
  padding: 0 0 15% 10px;
}

.modalImage {
  width: 100%;
}

.blocButtonConformity {
  margin-left: 85%;
  margin-bottom: 20px;
  margin-top: -2.5%;
}

.formLabel {
  color: #0A405D !important;
  font-size: 15px !important;
  font-weight: bold !important;
}

.formInput {
  border: 0px !important;
  border-bottom: 1px solid #0A405D !important;
  background: #f7f7f8 !important;
  font-size: 15px !important;
}

.formInputCodeTiers {
  border: 0px !important;
  border-bottom: 1px solid rgba(10, 64, 93, 0.4) !important;
  background: #f7f7f8 !important;
  font-size: 15px !important;
  color: rgba(136, 136, 136, 0.932);
  margin-top: 13px;
  font-weight: 1000;
}

.formInputCodeTiers:hover {
  background: rgba(99, 110, 114,0.1) !important;
  padding: 1px 0px;
  border: 0px !important;
  /* color: #fff; */
}

@media screen and (max-width: 1210px) {

  .nonCompliant {
    background-color: #C53211 !important;
    color: white !important;
    padding: 5px;
    font-size: 9px;
    text-align: center;
    width: 136px;
    height: 29px;
    border-radius: 10px;
    margin-top: -6px;
    opacity: 1;
  }

  .criticalMIN,
  .criticalMAJ,
  .criticalNEU {
    color: white !important;
    padding: 7px 17px !important;
    font-size: 9px !important;
    font-weight: bold !important;
    width: 100px !important;
    height: 30px !important;
    border-radius: 10px !important;
    opacity: 1 !important;
    top: 362px;
    left: 100px;
  }

  .compliant {
    background-color: rgba(149, 192, 67, 1) !important;
    color: white !important;
    padding: 5px;
    border-radius: 5px;
    font-size: 9px;
    text-align: center;
    width: 136px;
    height: 29px;
    border-radius: 10px;
    opacity: 1;
  }

  .noneCompliant {
    background-color: #97b6e3 !important;
    color: white !important;
    padding: 5px;
    font-size: 9px;
    text-align: center;
    width: 136px;
    height: 29px;
    border-radius: 10px;
    opacity: 1;
  }

  .cardIndicator{
    width: 98%;
  }
  
  .contentCard{
    display: flex;
    justify-content: space-between;
    padding: 1.2em;
    font-weight: bold;
    cursor: pointer;
  }
  
  /* .contentCard::after {
    content: "\276F";
    transform: rotate(90deg);
    width: 1.2em;
    height: 1.2em;
    text-align: center;
    transition: all 0.35s;
  } */
  
  .indicator{
    margin-right: 5px;
    padding-top: 0.5%;
  }
  
  .indicatorContent{
    /* background-color: gray; */
    width: 70%;
    height: 5vh;
    font-size: 11px;
    font-weight: bold;
    padding-left: 10px;
    padding-top: 0.5%;
    word-wrap: break-word;
  }
  
  .indicatorButton{
    display: flex;
    justify-content: flex-start;
    padding-top: 0.5%;
  }

  .blocButtonConformity {
    margin-left: 85%;
    margin-bottom: 20px;
    margin-top: -4.5%;
  }

  .formLabel {
    font-size: 13px !important;
  }

  .formInput {
    font-size: 13px !important;
  }
}

@media screen and (max-width: 1125px) {

  .nonCompliant {
    background-color: #C53211 !important;
    color: white !important;
    padding: 5px;
    font-size: 7px;
    width: 136px;
    height: 29px;
    border-radius: 10px;
    margin-top: -6px;
    opacity: 1;
  }

  .criticalMIN,
  .criticalMAJ,
  .criticalNEU {
    color: white !important;
    padding: 7px 17px !important;
    font-size: 7px !important;
    font-weight: bold !important;
    width: 100px !important;
    height: 30px !important;
    border-radius: 10px !important;
    opacity: 1 !important;
    top: 362px;
    left: 100px;
  }

  .compliant {
    background-color: rgba(149, 192, 67, 1) !important;
    color: white !important;
    padding: 5px;
    border-radius: 5px;
    font-size: 7px;
    width: 136px;
    height: 29px;
    border-radius: 10px;
    opacity: 1;
  }

  .noneCompliant {
    background-color: #97b6e3 !important;
    color: white !important;
    padding: 5px;
    font-size: 7px;
    width: 136px;
    height: 29px;
    border-radius: 10px;
    opacity: 1;
  }

  .cardIndicator{
    width: 98%;
  }
  
  .contentCard{
    display: flex;
    justify-content: space-between;
    padding: 1.2em;
    font-weight: bold;
    cursor: pointer;
  }
  
  /* .contentCard::after {
    content: "\276F";
    transform: rotate(90deg);
    width: 1.2em;
    height: 1.2em;
    text-align: center;
    transition: all 0.35s;
  } */
  
  .indicator{
    margin-right: 5px;
    padding-top: 0.5%;
  }
  
  .indicatorContent{
    /* background-color: gray; */
    width: 65%;
    height: 5vh;
    font-size: 11px;
    font-weight: bold;
    padding-left: 10px;
    padding-top: 0.5%;
    word-wrap: break-word;
  }
  
  .indicatorButton{
    display: flex;
    justify-content: flex-start;
    padding-top: 0.5%;
  }

  .blocButtonConformity {
    margin-left: 85%;
    margin-bottom: 20px;
    margin-top: -5.5%;
  }

  .formLabel {
    font-size: 11px !important;
  }

  .formInput {
    font-size: 11px !important;
  }

  .formInputCodeTiers {
    font-size: 11px !important;
    margin-top: 13px;
  }
}

@media (min-width: 600px) and (max-width: 1000px) {
  .nonCompliant {
    font-size: 5px;
  }

  .criticalMIN,
  .criticalMAJ,
  .criticalNEU {
    font-size: 7px !important;
  }

  .compliant {
    font-size: 5px;
  }

  .noneCompliant {
    font-size: 5px;
  }

  .titleCritere {
    margin-left: -73%;
  }

  .titleCritere h4 {
    font-size: 6px;
    margin-top: 3%;
  }

  /* .blocButtonConformity {
    margin-left: 85%;
    margin-bottom: 20px;
    margin-top: -6.5%;
  } */

  .formLabel {
    font-size: 9px !important;
  }

  .formInput {
    font-size: 9px !important;
  }

  .formInputCodeTiers {
    font-size: 9px !important;
    margin-top: 13px;
  }
}


