.accordionTitle {
  color: #0a405d;
  font-size: 2rem;
}

.expander {
  margin-bottom: 20px;
}

.accordion {
  
}

.accordionContent {
  padding: 20px;
}

.count {
  font-size: 2rem;
  color: white;
  background-color: #95c043;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
}

.itemContainer {
  height: 80vh;
  overflow-y: auto;
  padding: 10px;
}

.itemLightContainer {
  margin-bottom: 20px;
}

.itemPicto {
  height: 25px;
  /* margin-right: 15px; */
}

.expanderTitle {
  font-size: 2rem;
  margin-bottom: 20px;
}

.title {
  display: flex;
  align-items: center;
  font-size: 2rem;
  justify-content: space-between;
  /* margin-bottom: 15px; */
}

.information {
  
}

.innerTitle {
  display: flex;
  align-items: center;
}

.informationContainer {
  /* margin-left: 50px; */
}

.itemPictoContainer {
  margin-right: 15px;
}