.error {
  font-family: PrintaniaLight, Arial, Helvetica, sans-serif;
  color: red;
}

.fullWidth {
  width: 100%;
}

.icon {
  height: 30px;
  width: 30px;
  background: url("../../../../../assets//pictos/productionspecificationsPictoBlue.png")
    center/36px 36px no-repeat;
  cursor: pointer;
}
