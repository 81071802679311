.tableWrapper {
  height: 300px;
  overflow-y: auto;
  background-color: white;
  padding: 2rem;
  padding-top: 0;
}

.title {
  display: flex;
  align-items: center;
  font-size: 2rem;
  justify-content: space-between;
  /* margin-bottom: 15px; */
}