.mainLayout {
  height: 100%;
  background-color: #f7f7f8;
  z-index: 0;
  position: relative;
}

/* .mainLayout .wrapDataContract {
  color: red;
  background-color: red;
  overflow: scroll;
  width: 100%;
  height: 50vh;
  z-index: 2;
} */