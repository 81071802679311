.editPicto {
  height: 2.5rem;
}

.editContainer {
  display : flex;
  justify-content: space-between;
}

.itemHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
}

.itemTitle {
  display: flex;
  align-items: center;
  font-size: 2.5rem;
}

.information {
  display: inline-flex;
  margin-right: 20px;
}

.label {

}

.itemContent {

}

.itemContainer {
  background-color: #95C043;
  border-radius: 10px;
  color: white;
  padding: 20px;
}

.itemPicto {
  margin-right: 10px;
  height: 3.5rem;
}

.itemPictoContainer {
  display: flex;
  align-items: center;
  font-size: 2.5rem;
}


.fileAvailable {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.fileUnavailable {
  color: #808080;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.iconText {
  font-size: 1.2rem;
  margin-left:5px; 
}

.genericTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.eyeIcon {
  width: 25px;
  margin-left: 10px;
  color: #0A405D;

}