/* .commentInput {
    height: 50px;
    border-radius: 5px;  
    margin: 10px auto; 
}
*/

.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mentions {
  margin: 1em 0;
  width: 300px;
  background-color: #FFF;
  font-family: OpenSans;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 200px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
  /* width: 200px !important; */
  height: 50px;
  /* margin: auto !important;
  position: relative !important;  */
  background-color: #fff;
  border-radius: 3px;
  transition: 0.3s ease-in-out;
  padding: 9px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  border: 1px solid #dedede;
}

.mentions--multiLine .mentions__control {
  font-family: OpenSans;
  font-size: 1.3rem;
}
.mentions--multiLine .mentions__highlighter {
  /* border: 1px solid transparent;
  padding: 9px;
  min-height: 63px; */
  padding: 10px;
}
.mentions--multiLine .mentions__input {
  border: 1px solid silver;
  padding: 9px;
  outline: 0;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
  max-height: 500px;
  overflow-y: scroll;
}

.mentions__suggestions__item {
  /* padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15); */
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color:#95c043;
  color: white;
}

.mentions__mention {
  /* position: relative; */
  z-index: 1;
  color: blue;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white;
  text-decoration: underline;
  pointer-events: none;
}