
.statusBtn {
    font-family: OpenSans-SemiBold, Arial, Helvetica, sans-serif !important;
    font-size: 1.2rem !important;
    text-transform: uppercase !important;
    border: 1px solid transparent;
    background-color: #95c043;
    color: white;
    padding:3px 5px;
    /* cursor: pointer; */
    border-radius: 5px;
    /* line-height: 1rem; */
    text-align: center;
    white-space: nowrap;
}

.warning {
    background-color: #C53211;
    color: white;
    border: 1px solid #C53211;
}

.disabled {
    border: 1px solid transparent;
    background-color: #d3d3d3;
    color: white;
    cursor: not-allowed;
}
