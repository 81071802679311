.tableWrapper {
  /* height: 300px; */
  overflow-y: auto;
  /* overflow-x: hidden; */
  padding: 2rem;
  padding-top: 0;
}

.searchPicto {
  height: 15px;
}