.formLine {
  width: 80%;
  margin-bottom: 20px;
  margin-left: 10%;
}

.productData {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-bottom: 20px;
  margin-left: 10%;
}

.productDataForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.productDataContainer {
  display: flex;
  flex-direction: column;
}

.formActions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}

.navigationActions {
  display: flex;
  justify-content: space-between;
}

.backNavigation {
  margin-right: 10px;
}
