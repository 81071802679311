.modal {
    background-color: #F7F7F8;
    font-family: OpenSans-Bold, Arial, Helvetica, sans-serif;
    font-size: 2rem;
    height: 30vh;
    padding: 50px;
    text-align: center;
}

.title {
    margin-bottom: 50px;
}

.buttons {
    display: flex;
    justify-content: center;
}

.button {
    margin-right: 20px;
}


