.title {
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  line-height: 33px;
  color: rgba(10, 64, 93, 0.78);
}

.refreshAudits {
  margin-top: 3%;
  display: block;
}

.refreshAudits a {
  text-decoration: none;
  font-size: 1.5em;
  margin-left: 5px;
  margin-top: -4%;
}

.principalCard {
  background-color: white;
  border-radius: 12px;
  width: 100%;
  height: 183px;
  padding: 10px;
  margin-top: 3%;
  margin-bottom: 3%;
}

.principalCardTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #0a405d;
}
.mainGraphContainer {
  padding: 5px;
  margin-top: 10px;
  width: 100%;
}
.graphContainer {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
}

.flexCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 65px;
}

.cardExpert {
  width: 381px;
}

.cardMembers {
  width: 207px;
}

.cardRisk {
  width: 130px;
}

.cardCritic {
  width: 130px;
}

.cardNotValid {
  width: 130px;
}

.cardNull {
  display: none;
}

.cardColorExpert {
  background: #95bf41 !important;
}

.cardColorMembers {
  background: #608a0c !important;
}

.cardColorRisk {
  background: #f2cb05 !important;
}

.cardColorCritic {
  background: #f28705 !important;
}

.cardColorNotValid {
  background: rgba(255, 0, 0, 0.82) !important;
}

.textCardColorPourcent {
  font-size: 25px;
  color: white;
  font-weight: bold;
}

.textCardColorActor {
  font-size: 12px;
  color: white;
}

.miniCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  padding: 10px;
  margin-top: 10px;
}

.miniCardColor {
  display: inline-block;
  width: 17px;
  height: 18px;
}

.miniCardText {
  font-size: 10px;
  font-weight: bold;
  margin-left: 10px;
}

.miniContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
