.ecosystemDisabled {
    width: 100%;
    height: 100%;
}

.modalBloc {
    height : 70vh;
    background-color: #f7f7f8 !important;
}

.modalHeader {
    text-align: center;
    font-family: OpenSans-Bold;
    font-size: 2.1rem;
  }
  
  .modalContent{
    /* display: flex; */
    flex-direction: column;
    font-family: OpenSans-Bold;
    justify-content: center;
    align-items: center;
    /* height: 80%; */
    font-size: 1.4rem;
  }
  
  .modalSearch{
    font-family: OpenSans-Bold;
    margin: 1rem;
    text-align: center;
  }
  .inputContainer {
    text-align: center;
    position: relative;
    width: 100%;
  }
  
  
  .modalInput {
    border-radius: 5px;
    box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.11);
    height: 4rem;
    width:450px;
  }

  .modalList{
    position: absolute;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.11);
  }
  .modalListContent {
    background-color: white;
    border: 0.5px solid lightgray;
    text-align: center;
    font-weight: bold;
    text-align: left;
    padding : 1rem;
    font-size: 1.2rem;
  }
  
  .modalListContent:hover{
    background-color: #0A405D;
    color: #FFF;
  }

  .createOption {
    text-align: center;
  }

  .modalOption{
    /* margin: 2%; */
  }