.editImg{
    width: 10px;
    margin-right: 3px;
}

.classicBtn {
    display: flex;
    justify-content: space-between;
    font-family: OpenSans-SemiBold, Arial, Helvetica, sans-serif !important;
    font-size: 1.4rem;
    text-transform: uppercase !important;
    border: 1px solid transparent;
    background-color: #95c043;
    color: white;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 10px;
    line-height: 1.4rem;
    text-align: center;
    box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.11);
  }

  .warningBtn {
    display: flex;
    justify-content: space-between;
    font-family: OpenSans-SemiBold, Arial, Helvetica, sans-serif !important;
    font-size: 1.4rem;
    text-transform: uppercase !important;
    border: 1px solid transparent;
    background-color: #C53211;
    color: white;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 10px;
    line-height: 1.4rem;
    text-align: center;
    box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.11);
  }

  .retrackBtn {
    display: flex;
    justify-content: space-between;
    font-family: OpenSans-SemiBold, Arial, Helvetica, sans-serif !important;
    font-size: 1.4rem;
    text-transform: uppercase !important;
    border: 1px solid transparent;
    background-color: #e3a618;
    color: white;
    padding: 10px 15px;
    cursor: not-allowed;
    border-radius: 10px;
    line-height: 1.4rem;
    text-align: center;
    box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.11);
  }