.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}


.header {
    text-align: center;
    margin-top: 5%;
}

.logo {
    width: 50%;
    margin-bottom: 0.5rem;
}

.title {
    font-size: 1.5rem;
}

.formWrapper {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer {
    margin-bottom: 10px;
}