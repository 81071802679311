.viewActorContainer {
  margin-top: 5vh;
  margin-left: 7%;
  width: 86%;
}

.viewActorLightItem {
  background-color: #95c043;
  border-radius: 10px;
  color: white;
  padding: 20px;
}

.viewActorLightItemHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
}

.viewActorItemPicto {
  display: flex;
  align-items: center;
  font-size: 2.5rem;
}

.viewActorItemPictoLight {
  margin-right: 10px;
  height: 3.5rem;
}

.viewActorEdit {
  display: flex;
  justify-content: space-between;
}

.viewActorEditBtn {
  height: 2.5rem;
}

.viewActorClick {
  position: relative;
}

.viewActorClickBtn {
  display: block;
  cursor: pointer;
}

.viewActorContent {
}

.viewActorContentInfo {
  display: inline-flex;
  margin-right: 20px;
}

.ui.table thead tr:first-child > th {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: red !important;
  color: white !important;
}
