.tile,
.hoveredTile {
  height: 160px;
  /* width: 350px; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  background-color: white;
  border-radius: 10px;
}

.hoveredTile {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.56);
}

.notificationWrapper {
position: relative;
  height: 40px;
}

.notificationContainer {
  position: absolute;
  background-color: #95c043;
  width: 40px;
  height: 40px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.notification {
  color: white;
  font-size: 1.6rem;
  margin-top: 8px;
}

.favoriteContainer {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.favorite {
  height: 25px;
}

.imageContainer {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.image {
  max-height: 50px;
  max-width: 50px;
  margin-top: 50%;
  transform: translate(0px, -50%);
}

.status {
  font-family: OpenSans-LightItalic, Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
}

.title {
  font-size: 1.6rem;
}

.subTitle {
  font-size: 1.2rem;
}

.textWrapper {

}

.tileContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80px;
  justify-content: space-evenly;
  position: relative;
}

