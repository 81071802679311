.mainWrapper {
  height: 100vh;
  /* margin: 0;
  padding: 0; */
  overflow: hidden;
}

/* .resultsWrapper {
}

.resultLine {
} */

.headerWrapper {
  margin-bottom: 10px;
}

.specDetails {
  display: flex;
  justify-content: space-evenly;
  font-size: smaller;
}

.header {
  display: flex;
}

.resultsList {
  border-bottom: 1px solid red;
}

.titleHeader {
  font-size: x-large;
}

.topHeader {
  display: flex;
}

.middleHeader {
  display: flex;
  justify-content: left;
}

.percentage {
  display: flex;
  flex-direction: row;
}

.infoCard {
  display: flex;
  background-color: white;
  width: 320px;
  height: 120px;
  border-radius: 6px;
  margin-top: 50px;
  margin-bottom: 20px;
  margin-right: 10px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0px 3px 10px gray;
  font-weight: bolder;
}

.infoCardInd {
  width: 365px;
}

/* .infoCardTaux {
  width: 300px;
} */


.bottomHeader {
  display: flex;
  justify-content: space-between;
}

.buttonWrapper {
  display: flex;
}

.buttonFilter {
  margin-right: 10px;
}

.infoTitle {
  margin-bottom: 15px;
  padding-top: 3.5%;
}

.logoSvg {
  width: 15px;
  height: 16px;
}

.logoSvgThumb {
  width: 15px;
  height: 16px;
}

.card {
  background: white;
  width: 578px;
  height: auto;
  box-shadow: 0px 3px 10px gray;
  font-weight: bolder;
  border-radius: 6px;
  margin-top: 50px;
  margin-bottom: 20px;
  margin-right: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: -15px;
  /* text-align: center; */
}

.blocUp {
  /* float: left; */
  /* width: 60%; */
  /* display: inline-block; */
  /* margin-bottom: 2%; */
  /* margin-left: 2%; */
  /* margin-right: 1%; */
}

.blocDown {
  /* float: right; */
  /* width: 45%; */
  /* display: inline-block; */
  /* margin-bottom: 2%; */
  /* margin-right: auto; */
  /* margin-left: -1%; */
}

.blocDownLarge {
  margin-left: 2%;
  margin-right: 30%;
}

.blocImage {
  text-align: center;
  /* display: flex;
  justify-content: center; */
  /* margin-top: 5%;
  margin-bottom: 4%; */
}

/* .blocContent {
  width: 100%;
} */

/* .blocContentLeft {
  width: 100%;
  margin-right: 3em;
} */

/* .blocContentRight{
  width: 100%;
  margin-left: 3em;
} */

.infoTitleCard {
  /* margin-bottom: 6px; */
  padding-top: 2%;
}

.globalBloc {
  /* display: flex;
  flex-direction: row; */
  /* justify-content: space-between; */
  /* margin-left: 8%;
  margin-right: 8%;
  margin-top: 2.5%; */
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}

.indicatorTitle {
  font-size: 10px;
}


/* Toggle Button */

.switchButton {
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-bottom: 2%;
  /* margin-left: 68%; */
}

.blocSwitchButton {
  float: left;
}

.blocSwitchButton div {
  display: block;
  padding: 5px;
}

/* color toggle */
.toggleActive {
  color: rgba(10, 64, 94, 0.5);
  font-size: 1.7rem;
  font-weight: bold;
  margin-right: 12px;
  /* margin-top: -32px; */
}

.toggleDisable {
  color: #0a405e;
  font-size: 1.7rem;
  font-weight: bold;
  margin-right: 12px;
  /* margin-top: -32px; */
}


.checkButton[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.toggleButton {
  cursor: pointer;
  text-indent: -9999px;
  width: 55px;
  height: 18px;
  background: #0a405e;
  display: block;
  border-radius: 40px;
  position: relative;
}

.toggleButton:after {
  content: '';
  position: absolute;
  top: 0px;
  left: 2px;
  width: 18px;
  height: 18px;
  background: #fff;
  border-radius: 40px;
  transition: 0.3s;
}

.checkButton:checked+label {
  background: #0a405e;
}

.checkButton:checked+label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.toggleButton:active:after {
  width: 130px;
}

/* .indicatorTitleLg {
  font-size: 10px;
} */

/* .indicators {
  font-size: 9.5px;
}

.indicatorsMax {
  font-size: 9.5px;
} */



/* .icon {
  
  background-size: cover;
  fill: red;
}

.iconArrow:hover,
.iconArrow:focus {
  filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
} */

.cardText {
  font-size: 2.2rem;
  font-weight: bold;
}

.cardTextSub {
  font-size: 1rem;
}

.smallText {
  font-size: 0.9rem;
}