.form {

}

.actorsCheckWrapper {
  height: calc(90vh - 7rem - 250px);
}

.formActions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}

.navigationActions {
  display: flex;
  justify-content: space-between;
}

.backNavigation {
  margin-right: 10px;
}

.nextNavigation {

}