.accordion__section {
    display: flex;
    flex-direction: column;
  }
  
  .accordionContract {
    /* background-color: #fff;
    color: #444;
    cursor: pointer;
    padding: 18px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease; */
    display: flex;
  justify-content: space-between;
  padding: 1.2em;
  font-weight: bold;
  cursor: pointer;
  }
  
  .active {
    /* background-color: #fff; */
  }
  
  .accordion__title {
    font-family: sans-serif;
    font-weight: 600;
    font-size: 14px;
  }
  
  .accordion__icon {
    margin-left: auto;
    transition: transform 0.6s ease;
  }
  
  .rotate {
    transform: rotate(180deg);
  }
  
  .accordion__content {
      max-height: 0;
      padding: 0 1em;
      transition: all 0.35s;
    
  }
  
  .accordion__text {
    font-family: sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 18px;
  }

  .indicator{
    margin-right: 5px;
    padding-top: 0.5%;
  }

  .criticalMIN,
.criticalMAJ,
.criticalNEU {
  color: white !important;
  padding: 7px 17px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  width: 100px !important;
  height: 30px !important;
  border-radius: 10px !important;
  opacity: 1 !important;
  top: 362px;
  left: 100px;
}

.criticalMIN {
  background-color: #FBCA26 !important;
}

.criticalMAJ {
  background-color: #F28705 !important;
}

.criticalNEU {
  background-color: gray !important;
}

.indicatorButton{
  display: flex;
  justify-content: flex-start;
  padding-top: 0.5%;
}

.indicatorContent{
  width: 80%;
  height: 5vh;
  font-size: 11px;
  font-weight: bold;
  padding-left: 10px;
  padding-top: 0.5%;
  word-wrap: break-word;
}

.compliant {
  background-color: rgba(149, 192, 67, 1) !important;
  color: white !important;
  padding: 5px;
  border-radius: 5px;
  font-size: 13px;
  text-align: center;
  width: 136px;
  height: 29px;
  border-radius: 10px;
  opacity: 1;
}

.nonCompliant {
  background-color: rgba(255, 0, 0, 0.82) !important;
  color: white !important;
  padding: 5px;
  font-size: 13px;
  text-align: center;
  width: 136px;
  height: 29px;
  border-radius: 10px;
  opacity: 1;
}

.noneCompliant {
  background-color: #97b6e3 !important;
  color: white !important;
  padding: 5px;
  font-size: 14px;
  text-align: center;
  width: 136px;
  height: 29px;
  border-radius: 10px;
  opacity: 1;
}

@media screen and (max-width: 1210px) {

  .nonCompliant {
    background-color: #C53211 !important;
    color: white !important;
    padding: 5px;
    font-size: 9px;
    text-align: center;
    width: 136px;
    height: 29px;
    border-radius: 10px;
    margin-top: -6px;
    opacity: 1;
  }

  .criticalMIN,
  .criticalMAJ,
  .criticalNEU {
    color: white !important;
    padding: 7px 17px !important;
    font-size: 9px !important;
    font-weight: bold !important;
    width: 100px !important;
    height: 30px !important;
    border-radius: 10px !important;
    opacity: 1 !important;
    top: 362px;
    left: 100px;
  }

  .compliant {
    background-color: rgba(149, 192, 67, 1) !important;
    color: white !important;
    padding: 5px;
    border-radius: 5px;
    font-size: 9px;
    text-align: center;
    width: 136px;
    height: 29px;
    border-radius: 10px;
    opacity: 1;
  }

  .noneCompliant {
    background-color: #97b6e3 !important;
    color: white !important;
    padding: 5px;
    font-size: 9px;
    text-align: center;
    width: 136px;
    height: 29px;
    border-radius: 10px;
    opacity: 1;
  }

  .cardIndicator{
    width: 98%;
  }
  
  .contentCard{
    display: flex;
    justify-content: space-between;
    padding: 1.2em;
    font-weight: bold;
    cursor: pointer;
  }
  
  /* .contentCard::after {
    content: "\276F";
    transform: rotate(90deg);
    width: 1.2em;
    height: 1.2em;
    text-align: center;
    transition: all 0.35s;
  } */
  
  .indicator{
    margin-right: 5px;
    padding-top: 0.5%;
  }
  
  .indicatorContent{
    /* background-color: gray; */
    width: 70%;
    height: 5vh;
    font-size: 11px;
    font-weight: bold;
    padding-left: 10px;
    padding-top: 0.5%;
    word-wrap: break-word;
  }
  
  .indicatorButton{
    display: flex;
    justify-content: flex-start;
    padding-top: 0.5%;
  }

  .blocButtonConformity {
    margin-left: 85%;
    margin-bottom: 20px;
    margin-top: -4.5%;
  }

  .formLabel {
    font-size: 13px !important;
  }

  .formInput {
    font-size: 13px !important;
  }
}

@media screen and (max-width: 1125px) {

  .nonCompliant {
    background-color: #C53211 !important;
    color: white !important;
    padding: 5px;
    font-size: 7px;
    width: 136px;
    height: 29px;
    border-radius: 10px;
    margin-top: -6px;
    opacity: 1;
  }

  .criticalMIN,
  .criticalMAJ,
  .criticalNEU {
    color: white !important;
    padding: 7px 17px !important;
    font-size: 7px !important;
    font-weight: bold !important;
    width: 100px !important;
    height: 30px !important;
    border-radius: 10px !important;
    opacity: 1 !important;
    top: 362px;
    left: 100px;
  }

  .compliant {
    background-color: rgba(149, 192, 67, 1) !important;
    color: white !important;
    padding: 5px;
    border-radius: 5px;
    font-size: 7px;
    width: 136px;
    height: 29px;
    border-radius: 10px;
    opacity: 1;
  }

  .noneCompliant {
    background-color: #97b6e3 !important;
    color: white !important;
    padding: 5px;
    font-size: 7px;
    width: 136px;
    height: 29px;
    border-radius: 10px;
    opacity: 1;
  }

  .cardIndicator{
    width: 98%;
  }
  
  .contentCard{
    display: flex;
    justify-content: space-between;
    padding: 1.2em;
    font-weight: bold;
    cursor: pointer;
  }
  
  .indicator{
    margin-right: 5px;
    padding-top: 0.5%;
  }
  
  .indicatorContent{
    /* background-color: gray; */
    width: 65%;
    height: 5vh;
    font-size: 11px;
    font-weight: bold;
    padding-left: 10px;
    padding-top: 0.5%;
    word-wrap: break-word;
  }
  
  .indicatorButton{
    display: flex;
    justify-content: flex-start;
    padding-top: 0.5%;
  }

  .blocButtonConformity {
    margin-left: 85%;
    margin-bottom: 20px;
    margin-top: -5.5%;
  }

  .formLabel {
    font-size: 11px !important;
  }

  .formInput {
    font-size: 11px !important;
  }
}

@media (min-width: 600px) and (max-width: 1000px) {
  .nonCompliant {
    font-size: 5px;
  }

  .criticalMIN,
  .criticalMAJ,
  .criticalNEU {
    font-size: 7px !important;
  }

  .compliant {
    font-size: 5px;
  }

  .noneCompliant {
    font-size: 5px;
  }

  .titleCritere {
    margin-left: -73%;
  }

  .titleCritere h4 {
    font-size: 6px;
    margin-top: 3%;
  }

  /* .blocButtonConformity {
    margin-left: 85%;
    margin-bottom: 20px;
    margin-top: -6.5%;
  } */

  .formLabel {
    font-size: 9px !important;
  }

  .formInput {
    font-size: 9px !important;
  }
}
