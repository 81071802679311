  .dropdown {
    position: absolute;
    right: -100%;
    background-color: #efefef; 
    transform: translateX(-45%);
    overflow: hidden;
    width: 150%;
    border-bottom-right-radius: 15%;
    border-bottom-left-radius: 15%;
  }
  
  .menu-item {
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    margin-bottom: 2px;
  }
  
  .menu-item:hover {
    background-color: #0a405d;
    color: white;
    cursor: pointer;
  }

  .dropdown-container {
    position: relative;
  }