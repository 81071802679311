.form {

}

.formContainer {

}

.formLine {
  width: 80%;
  margin-bottom: 20px;
  margin-left: 10%;
}

.inputLabel {

}

.error {

}

.cancelActions {

}

.formActions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}

.navigationActions {
  display: flex;
  justify-content: space-between;
}

.backNavigation {
  margin-right: 10px;
}

.nextNavigation {

}


.inputContainer {
  text-align: center;
  position: relative;
  width: 100%;
}

.modalInput {
  border-radius: 5px;
  box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.11);
  height: 4rem;
  width:80%;
}


.modalList{
  position: absolute;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.11);
  z-index: 1;
}
.modalListContent {
  background-color: white;
  border: 0.5px solid lightgray;
  text-align: center;
  font-weight: bold;
  text-align: left;
  padding : 1rem;
  font-size: 1.2rem;
}

.modalListContent:hover{
  background-color: #0A405D;
  color: #FFF;
}

.inputWrapper {
  /* width: 80%; */
}

.input {
  font-family: OpenSans, Arial, Helvetica, sans-serif;
  min-width: 5em !important;
  border-radius: 11px !important;
  border: solid 1px #000 !important;
  text-align: center !important;
  min-height: 1em !important;
  width: 100%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 11px !important;
  border-color: #d3d3d3 !important;
}



