.commentBloc{
    margin: 10px
}

.comment {
    margin: 10px;
    border-radius:  5px;
    padding: 5px 5px 0px 5px;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #FFF;
}


.commentHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;

}

.commentAuthor {
    font-family: OpenSans-bold ;
    font-size: 1rem;
}


.commentTitle {
    align-self: center;
    text-decoration: underline;
}

.commentDate {
    font-style: italic;
    text-align: right;
    /*v2*/
    font-size: 0.9rem;
}