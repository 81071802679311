.alertWrapper {
    height: 100vh;
}

.loaderWrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    /* top: 35px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0px 3px 1px rgba(50, 50, 93, 0.11);
    border: 1px solid transparent;
    cursor: pointer;
    font-family: OpenSans-Light; */
}

.statusModalContent{
    background-color: white;
    font-family: OpenSans-Bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.statusModalTitle {
    font-family: OpenSans-Bold;
    font-size: 2.5rem ;
    margin: 30px auto;
}

.inputZone {
    height: 150px;
    width: 300px;
    /* margin: 30px; */
}

.buttonsBloc {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 30px auto;
    justify-content: space-around;
    height: 150px;
}

.historyTrigger {
    font-family: OpenSans;
    font-size: 1.5rem;
    margin-bottom: 10px;
    cursor: pointer;
    display: inline-block;
}

.overlay {
   background-color: #F7F7F8
}

