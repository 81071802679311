.layout {
  font-size: 2.5rem;
  font-family: OpenSans-Bold, Arial, Helvetica, sans-serif;
  margin-bottom: 3vh;
}

.white {
  color: white;
}

.inverted {
  color: #0a405d;
}