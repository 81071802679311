.hint {
  font-size: 1.2rem;
  color: #b3b3b3;
}

.error {
  color: red;
  font-size: 1.2rem;
}

.uploadBtn {
  width: 25px;
  height: 25px;
  background: url("../../../../../assets/pictos/uploadPictoBlue.svg") center/25px 25px
    no-repeat;
  border: none;
  cursor: pointer;
}

.triggerBtn {
  border: 1px solid;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.warning {
   color: #C53211;
   font-weight: bold;
   font-size: 1.3rem;
   margin-bottom: 2%;
}

.warningPicto {
  width: 15px;
}

