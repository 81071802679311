.list {
    display: flex;
    justify-content: center;
  }
  

.simpleCard {
    width: 100%;
    background-color: white;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    position: relative;
}

.cardLabel {
    font-size: 1.5rem;
}

.cardValue {
    font-size: 1.2rem;
}


.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
}

.element {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    flex-grow: 1;
}

.navigationContainer {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.subTitle {
    margin-top:15px;
}

.placeholder {
    margin-top: 100px;
}

.wrapper {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
}

.top {
    max-height: 50px;
}

.top2 {
    max-height: 70px;
    margin-bottom: 200px;
}

.middle{
    margin-top: 50px;
    max-height : 70px;
    min-width: 100%;
    margin-bottom: 50px;
}

.bottomCardGroup {
    margin-top: 70px;
}

.longDiv {
    max-height: 50px;
}

.tableWrapper {
    background-color: white;
    padding: 2rem;
    padding-top: 0;
}

.fileAvailable {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.fileUnavailable {
    color: #808080;
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.icon {
    width: 25px;
    margin-right: 10px;
    color: #0A405D;
    /* position: absolute; */
    bottom: 10px;
    right: 5px;
}

.iconText {
    font-size: 1.2rem;
    text-align: center;
}

.genericTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.eyeIcon {
    width: 25px;
    margin-right: 10px;
    color: #0A405D;
    bottom: 10px;
    /* right: 5px; */
  }


  .historyTrigger{
    font-size: 1.5rem;
    font-family: OpenSans, Arial, Helvetica, sans-serif ;
    text-transform: none;
    cursor: pointer;
    display: inline-block;
  }

  .fileContainer {
      display: flex;
      flex-direction: column;
  }

  .addDocButton {
      cursor: pointer;
      width: 25px;
      margin-right: 10px;
  }