.error {
  font-family: OpenSans-Light, Arial, Helvetica, sans-serif;
  color: red;
}

.hint {
  font-family: OpensSans-Light, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  color: #b2b2b2;
}

.required {
  margin-left: 2px;
}

.input {
  font-family: OpenSans, Arial, Helvetica, sans-serif;
  min-width: 5em !important;
  border-radius: 11px !important;
  border: solid 1px #000 !important;
  text-align: center !important;
  min-height: 1em !important;
  width: 100%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 11px !important;
  border-color: #d3d3d3 !important;
}

.inline {
  font-family: OpenSans, Arial, Helvetica, sans-serif;
  min-width: 5em !important;
  text-align: center !important;
  min-height: 1em !important;
  width: 100%;

  border: none !important;
  border-bottom: 1px solid !important;
  border-color: #0A405D !important;
  border-radius: 0 !important;
  width: calc(100% - 30px);
  font-size: 1.2rem !important;
}


.inline:focus {
  outline: none;
}


.icon {
  width: 14px;
  color: #0A405D;
  opacity: 1 !important;
}