.form {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
}

.formContainer {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  /* max-width: 50%;
  flex-basis: 50%; */
}

.hint {
  font-size: 1.2rem;
  color: #999;
  margin-bottom: 1rem;
}

.title {
  font-family: PrintaniaBold, Arial, Helvetica, sans-serif;
  margin-bottom: 2rem;
  border-bottom: solid 1px #ccc;
  font-size: 1.6rem;
}

.formColumn {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.formLine {
  width: 100%;
  margin-bottom: 1rem;
  font-family: PrintaniaSemiLight, Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: row;
}

.pwdContainer {
  max-width: 400px;
  width: 50%;
  margin-right: 0;
}
.pwdContainer:first-of-type {
  padding-right: 1rem;
}

.formActions {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.submitError {
  font-family: PrintaniaLight, Arial, Helvetica, sans-serif;
  color: red;
}

@media (max-width: 767px) {
  .formContainer {
    max-width: 100%;
    width: 100%;
  }
  .formLine {
    flex-direction: column;
  }
  .pwdContainer {
    max-width: 100%;
    width: 100%;
    margin-right: 0;
  }
  .pwdContainer.new {
    margin-bottom: 1rem;
  }
  .pwdContainer:first-of-type {
    padding-right: 0;
  }
}
