.container {
    background-color: white;
    min-height: 100%;
    max-height: 100%;
    padding: 10px;
    font-family: OpenSans;
    background-color: #F7F7F7;
    box-shadow: 0px 0px 4px 3px rgba(163,153,163,0.63) !important;
    display: flex;
    flex-direction: column;
    max-height: 100%;
}

.title {
    text-align: center;
    font-family: OpenSans-bold;
    font-size: 1.5rem;
    margin-bottom: 30px;
}

.closeIcon {
    float: right;
    width: 12px;
}

.historyBloc {
    height: 50%;
    overflow: hidden;
    overflow-y: scroll;
}

.inputCommentBloc {
    padding: 0;
    border-top: 1px solid black;
}

.noHistory {
    font-style: italic;
    /* font-family: OpenSans; */
    font-size: 0.9rem;
    margin: 10px;
}