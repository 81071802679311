@media screen and (min-width: 900px) and (max-width: 1600px) {
    .tableWrapper {
      height: 40vh;
      width: 84.4vw;
      /* width: 84.5vw; */
    }
  }
  
  .tableWrapper thead th {
    position: sticky;
    top: 0;
  }
  
  .tableWrappe table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .tableWrappe th,
  .tableWrappe td {
    padding: 8px 16px;
  }
  
  .tableWrappe th {
    background: #eee;
  }
  
  .selected {
    background-color: red;
  }
  
  .topTableWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .buttonWrapper {
    display: flex;
  }
  
  .buttonFilter {
    margin-right: 10px;
  }
  
  .errorWrapper, .noResultWrapper {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .semanticLoader {
    height: 100px !important;
    position: relative !important;
  }
 
  