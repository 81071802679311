@font-face {
  font-family: OpenSans;
  font-display: swap;
  font-weight: 400;
  src: url(./assets/fonts/opensans/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: OpenSans-Bold;
  font-display: swap;
  font-weight: 700;
  src: url(./assets/fonts/opensans/OpenSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: OpenSans-SemiBold;
  font-display: swap;
  font-weight: 700;
  src: url(./assets/fonts/opensans/OpenSans-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: OpenSans-BoldItalic;
  font-display: swap;
  font-weight: 700;
  src: url(./assets/fonts/opensans/OpenSans-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: OpenSans-Italic;
  font-display: swap;
  src: url(./assets/fonts/opensans/OpenSans-Italic.ttf) format("truetype");
}

@font-face {
  font-family: OpenSans-Light;
  font-display: swap;
  font-weight: lighter;
  src: url(./assets/fonts/opensans/OpenSans-Light.ttf) format("truetype");
}

@font-face {
  font-family: OpenSans-LightItalic;
  font-display: swap;
  font-weight: lighter;
  src: url(./assets/fonts/opensans/OpenSans-LightItalic.ttf) format("truetype");
}

html {
  font-size: 62.5%;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
}

/* @media only screen and (min-width: 768px) {
  body {
    padding-top: 104px !important;
  }
} */
@media only screen and (max-width: 1024px) {
  html {
    font-size: 55%;
  }
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 50%;
  }
  /* body {
    padding-top: 60px !important;
  } */
}
@media only screen and (max-width: 320px) {
  /* body {
    padding-top: 40px !important;
  } */
}

/* TODO : revoir le line-height pour la police responsive */

body {
  line-height: 1.48;
  background-color: #fff;
  color: #0a405d;
  /* padding-top: 104px; */
  overflow-x: hidden;
  font-family: OpenSans-Light, Arial, Helvetica, sans-serif;
}

html,
body {
  width: 100%;
  height: 100%;
}

*,
::after,
::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100%;
}

button:focus {outline:0;}

button,
input,
optgroup,
select,
textarea {
  font-family: OpenSans, Arial, Helvetica, sans-serif;
}

b,
strong {
  font-family: OpenSans-Bold, Arial, Helvetica, sans-serif;
}

i {
  font-family: OpenSans-Italic, Arial, Helvetica, sans-serif;
}

h1 {
  font-family: OpenSans, Arial, Helvetica, sans-serif;
  font-size: 2.8rem;
}

h2 {
  font-family: OpenSans, Arial, Helvetica, sans-serif;
  font-size: 1.8rem;
}

h3 {
  font-family: OpenSans-Light, Arial, Helvetica, sans-serif;
  font-size: 1.8rem;
}

h4 {
  font-family: OpenSans, Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
}

h5 {
  font-family: OpenSans-Light, Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
}

h6 {
  font-family: OpenSans-Light, Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
}
/* stylelint-disable */

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
}

/* stylelint-enable */

a,
a:hover,
a:focus,
a:active,
a:visited {
  color: inherit;
}

a:link {
  text-decoration: none;
}

.full-width {
  width: 100%;
  max-width: 100% !important;
}

.full-height,
.responsive-full-height {
  height: 100%;
}

.clickable {
  cursor: pointer;
}

.centered-content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

/* Surcharge Semantic UI */

@media (max-width: 768px) {
  .responsive-full-height {
    height: initial;
  }
}

.custom-selector.ui.dropdown .menu > .item {
  font-size: inherit !important;
}

.ui.selection.dropdown {
  font-family: OpenSans-Light, Arial, Helvetica, sans-serif;
  border: 1px solid #d3d3d3 !important;
  border-radius: 0;
  /* min-width: 300px !important; */
}

.custom-selector.ui.selection.active.dropdown,
.custom-selector.ui.selection.active.dropdown:hover,
.custom-selector.ui.selection.active.dropdown .menu,
.custom-selector.ui.selection.active.dropdown:hover .menu,
.custom-selector.ui.selection.active.dropdown .menu:hover {
  border-color: #d3d3d3;
}

.custom-selector.ui.selection.active.dropdown .menu::-webkit-scrollbar-track {
  border-radius: 10px;
}

.footer-selector.ui.selection.active.dropdown,
.footer-selector.ui.selection.active.dropdown:hover,
.footer-selector.ui.selection.active.dropdown .menu,
.footer-selector.ui.selection.active.dropdown:hover .menu,
.footer-selector.ui.selection.active.dropdown .menu:hover {
  border-color: #fff !important;
}

.footer-selector.ui.dropdown,
.footer-selector.ui.dropdown .menu .item {
  text-transform: uppercase;
  border-color: #fff !important;
}
.custom-selector.ui.dropdown.selected,
.custom-selector.ui.dropdown .menu .selected.item {
  background-color: rgba(251, 224, 210, 0.5);
}

.custom-selector.ui.dropdown .menu > .item:hover {
  background-color: rgba(251, 224, 210, 0.95);
}

.custom-selector.ui.selection.dropdown .menu > .item {
  padding: 0.375em 1em !important;
  text-align: center;
}

.custom-selector.ui.selection.dropdown > .dropdown.icon {
  font-size: inherit;
  padding: 0.375em;
}

.custom-selector.ui.selection.dropdown > .default.text {
  color: #d3d3d3;
}

@media (max-width: 768px) {
  .custom-selector.ui.selection.dropdown .menu {
    max-height: 20rem;
  }
}

.footer-selector.ui.selection.dropdown > .default.text {
  color: rgba(100, 100, 100, 1);
}

.quantity-selector.ui.selection.dropdown .menu > .item {
  padding: 0.3em 0.4em !important;
  text-align: left;
}
.quantity-selector.ui.selection.dropdown > .dropdown.icon {
  font-size: 12px;
}

/* Surcharge globale pour avoir un focus plus compliant avec la charte graphique */

:focus {
  outline-color: transparent;
}

/* Surcharge React-Slick */

.horizontal .slick-slide {
  height: inherit !important;
  max-width: calc(100% / 4);
}

.horizontal .center .slick-slide {
  opacity: 0.75 !important;
  transition: opacity 0.2s;
}

.horizontal .center .slick-center {
  opacity: 1 !important;
  transition: opacity 0.2s;
}

.horizontal .slick-track {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
}

.horizontal .slick-slide > div {
  display: flex;
  height: 100% !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* radio button ovveride */

.ui.radio.checkbox {
  min-height: 26px;
  line-height: 18px;
  min-width: 26px;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.ui.radio.checkbox .box:before,
.ui.radio.checkbox label:before {
  width: 16px;
  height: 16px;
}
.ui.radio.checkbox input[type="checkbox"],
.ui.radio.checkbox input[type="radio"] {
  width: 16px;
  height: 16px;
}

.ui.checkbox .box:after, .ui.checkbox label:after {
  color: #0a405d !important;
}

.ui.checkbox .box,
.ui.checkbox label {
  padding-left: 2em;
  font-size: 1.4em;
  color: #0a405d;
}
.ui.radio.checkbox .box:after,
.ui.radio.checkbox label:after {
  width: 16px;
  height: 16px;
}
.ui.radio.checkbox input.hidden + label {
  font-family: OpenSans-Light, Arial, Helvetica, sans-serif;
}

/* Breadcrumb override */

.ui.breadcrumb .active.section {
  font-weight: normal;
  font-family: OpenSans, Arial, Helvetica, sans-serif;
}

/* react-date-picker override */

.react-date-picker__wrapper {
  border: none !important;
  width: 100%;
}

.react-date-picker__inputGroup {
  font-family: OpenSans-Light, Arial, Helvetica, sans-serif;
  border: 1px solid #d3d3d3;
  border-radius: 0;
  padding: 6px 6px !important;
  align-items: center !important;
  justify-content: center;
  background-color: #fff;
  color: black;
  /* min-width: calc((4px * ) */
}
.react-date-picker__calendar-button.react-date-picker__button > svg {
  stroke: #d3d3d3;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

/* Gestion de l'impression */

@media screen {
  .elementToPrintOnly,
  .elementToPrintOnly * {
    /* ces elements sont visible uniquement en mode impression */
    display: none;
  }
}

@media print {
  #root {
    position: absolute;
    left: 0;
    top: 0;
    width: auto;
    height: auto;
  }
  .elementToPrintOnly,
  .elementToPrintOnly * {
    display: block;
  }
  .elementNotToPrint,
  .elementNotToPrint * {
    display: none !important;
  }
}

/*Tabs*/
.ui.menu {
  margin-bottom: 0px;
  border-bottom: none;
  border: none;
}

.ui.link.menu .item:active, .ui.menu .link.item:active, .ui.menu a.item:active {
  background: #95c043;
  color: white;
}

.ui.menu > .item:first-child {
  border-radius: unset;
}

.ui.item.menu {
  background-color: transparent;
}

.ui.menu.item .item {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: #95c043;
  font-family: OpenSans-Bold, Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  color: white;
}

.ui.menu.item .item:hover {
  background-color: white;
  color:#0a405d;
}

.ui.menu .active.item {
  background-color: white;
  font-family: OpenSans-Bold, Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  color:#0a405d;
}

/*Table*/
.ui.table thead tr:first-child > th {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #FFF;
}

.ui.table thead th {
  font-family: OpenSans-Bold, Arial, Helvetica, sans-serif;
  color: #0a405d;
  font-size: 1.2rem;
}

.ui.table {
  font-family: OpenSans, Arial, Helvetica, sans-serif;
  color: #0a405d;
  font-size: 1rem;
  /* min-height: 220px; */
}

.ui.attached.segment {
  border: none
}

/*Sidebar*/
.ui.left.sidebar {
  width: auto;
}

.ui.accordion .active.content, .ui.accordion .accordion .active.content {
  display: inline;
}

.ui.styled.accordion {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

/* Inputs */
.ui.form textarea {
  border-radius: 0 !important;
  border-color: #d3d3d3 !important;
}

.ui.disabled.input {
  background: #d3d3d3;
}
.ui.disabled.input > input {
  background: #d3d3d3;
}
.ui.input > input {
  font-family: OpenSans-Light, Arial, Helvetica, sans-serif;
  border-radius: 11px;
  border: none;
}
.ui.input > input,
.ui.form input[type="tel"],
.ui.form input[type="email"] {
  border: none;
}

textarea {
  font-family: OpenSans-Light, Arial, Helvetica, sans-serif;
  border-color: #d3d3d3 !important;
}

/* CSS textes */

.help {
  font-size: 1.2rem;
}

/* rc-slider (slider/range picker) overrides */
.rc-slider-handle {
  border: solid 2px #f7ceb8 !important;
}
.rc-slider-track {
  background-color: #f7ceb8 !important;
}
.rc-slider-handle:active {
  border-color: #f7ceb8 !important;
  box-shadow: 0 0 5px #f7ceb8 !important;
  cursor: grabbing;
}

/*Loader*/
.ui.massive.loader:before, .ui.massive.loader:after {
  height: 100px;
  width: 100px;
}
