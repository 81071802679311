.layoutImage {
  height: 100%;
  width: 60%;
  position: absolute;
  top:0;
  left:0;
  z-index: -1;
  background-size: cover;
}

.layoutColor {
  height: 100%;
  width: 40%;
  position: absolute;
  top:0;
  left:60%;
  z-index: -1;
  background-color: #043f5f;
}

.content {
  width: 85%;
  margin-left: 15%;
  height: 100%;
}