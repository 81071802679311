.homeWrapper {}

.lightPaper {
    background-color: white;
    margin-bottom: 4vh;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    padding: 50px;
}

.lightPaperTable {
    background-color: white;
    margin-bottom: 4vh;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    padding: 15px;
}

.title {
    font-size: 2rem;
    margin-bottom: 15px;
}

.paperContent {
    display: flex;
    flex-direction: row;
}

.notifCategory {
    width: 100%;
}

.notifSubcategoryWrapper {
    display: flex;
    flex-direction: row;
}

.notifSubcategory {
    width: 50%;
    padding-left: 2rem;
}

.notifTitle {
    color: #95c043;
    font-size: 2rem;
    margin-bottom: 15px;
    padding-left: 2rem;
}

.notifSubtitleContainer {
    /* display:flex;
    flex-direction: row; */
    height: 50px;
    margin-bottom: 10px;
}

.notifSubtitle {
    font-family: OpenSans, Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
    width: calc(100% - 2.5rem - 10px);
    display: inline-flex;
}

.notifContent {}

.alertContainer {
    display: flex;
    flex-direction: row;
}

.criticalityPicto {
    width: 20px;
}

.criticalityContainer {
    margin-right: 10px;
}

.description {
    font-family: OpenSans, Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
}

.separator {
    border-left: 1px solid #0a405d;
}

.counter {
    color: white;
    background-color: #0a405d;
    font-size: 1.2rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.placeholder {
    margin-top: 150px;
}

.footer {
    text-align: center;
    margin-top: -5px;
    margin-bottom: 40px;
}