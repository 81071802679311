

.languageHandler {
  z-index: 1;
}

.currentLanguage, .language {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.currentFlagContainer, .flagContainer {
  cursor: pointer;
    display: flex;
}

.currentFlag, .flag {
  height: 2rem;
}

.currentLabel, .label {
  display: inline-block;
  font-size: 1.2rem;
  font-family: OpenSans, Arial, Helvetica, sans-serif;
  color: #0a405d;
  margin-left: 10px;
}

.languagesContainer {
  position: absolute;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.56);
}

.languagesContainer::before, .languagesContainer::after {
  content: '';
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  background: white;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.56);
  position: absolute;
  top: -5px;
  left: 3px;
  z-index: -1;
}

.languagesContainer::after {
  box-shadow: unset;
  z-index: 0;
}