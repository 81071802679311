.auditModal{
    margin: auto; 
    min-height: 60vh;
    padding: 30px;
    background-color: #f7f7f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: OpenSans-Bold;
  } 
  
  .auditModalTitle {
    font-weight: bold;
    font-size: 2.5rem;
    margin-bottom: 30px
  }
  
  .auditModalTable {
    height: 40vh;
  } 

  .buttonsBloc {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  
  .returnButton {
    max-width: 200px;
    margin-top: 5%;
    /* cursor: pointer; */
  }