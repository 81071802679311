.ecosystemDisabled {
    width: 100%;
    height: 100%;
  }

  .modalBloc {
    height : 70vh;
    background-color: #f7f7f8 !important;
    
  }
  
  .modalHeader {
    text-align: center;
    font-family: OpenSans-Bold;
    font-size: 2.1rem;
  }
  
  .modalContent{
    display: flex;
    flex-direction: column;
    font-family: OpenSans-Bold;
    justify-content: center;
    align-items: center;
    height: 80%;
    font-size: 1.4rem;
  }