
.modalContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding : 1%;
  min-height: 50vh;
}

.modalTitle {
    margin: 1% auto;
    text-align: center;
    font-size: 2rem;
    padding: 10px;
    font-family: OpenSans-bold;
}

.previewImage {
    width: 100%;
    margin: auto;
  }

  .inputWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px dashed lightgray;
    margin: 2% auto;
    padding: 50px;
  }

  .result {
      font-size: 2rem;
      margin: 20px auto
  }