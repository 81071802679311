.tableWrapper {
  /* height: 300px; */
  overflow-y: auto;
  /* overflow-x: hidden; */
  padding: 2rem;
  padding-top: 0;
}

.criticalityContainer {
  display: flex;
  justify-content: flex-start;
}

.criticalityPicto {
  max-width: 15px;
  max-height: 15px;
  margin-left: 15px;
}