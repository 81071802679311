.specificationWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sidebarWrapper {
  background-color: lightgreen;
}

.mainWrapper {}

.headerWrapper {
  display: flex;
}

.inputStyle {
  width: 290px; 
  height: 32px; 
  background: #FFFFFF;
  border-radius: 11px; 
  opacity: 1; 
  box-shadow: 0px 3px 6px #00000029;
  border: 0px solid #fff; 
  padding-left: 13px;
  font-size: 12px; 
  color: #0a405d
}

.resultCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-radius: 6px;
  margin-top: 5px;
  margin-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: -10px 0px 0px 0px #95bf41, 0px 3px 10px gray;
  font-size: large;
  font-weight: bold;
}

.specDetails {
  display: flex;
  justify-content: space-evenly;
  font-size: smaller;
}

.header {
  display: flex;
}

.input {
  width: 100%;
  border-radius: 6px;
  box-shadow: 0px 3px 10px gray;
}

.addButton {
  display: flex;
  justify-content: space-evenly;
}

.addIcon {
  width: 5%;
  height: 5%;
}

.addText {
  font-weight: 100;
}

.filterInput {
  width: 100%;
  color: #0a405d !important;
}

.toggle {
  display: flex;
  margin-left: 10px;
  align-self: flex-end;
}



/* filière card */

/* Float four columns side by side */
.column {
  float: left;
  width: 30%;
  padding: 0 5px;
}

/* Remove extra left and right margins, due to padding */
.row {
  margin: 15px auto;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive columns */
@media screen and (max-width: 766px) {
  /* .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  } */

  /* .card {
    box-shadow: -10px 0px 0px 0px #95bf41, 0px 3px 10px gray;
    padding: 12px;
    border-radius: 6px;
    margin: 5px;
  } */

  /* .card {
    box-shadow: -10px 0px 0px 0px #95bf41, 0px 3px 10px gray;
    padding: 12px;
    border-radius: 6px;
    margin: 5px;
    height: 13.5vh;
  } */
}

/* @media screen and (min-width: 1280px) and (max-width: 3000px) {
  
  .card {
    height: 25.5vh;
    background-color: red;
  }
} */

/* @media screen and (min-width: 1300px) and (max-width: 3500px) {
  .card {
    box-shadow: -10px 0px 0px 0px #95bf41, 0px 3px 10px gray;
    padding: 12px;
    border-radius: 6px;
    margin: 5px;
    height: 15.5vh; 
  }
}

@media screen and (min-width: 875px) and (max-width: 1300px) {
  .card {
    box-shadow: -10px 0px 0px 0px #95bf41, 0px 3px 10px gray;
    padding: 12px;
    border-radius: 6px;
    margin: 5px;
    height: 26vh;
  }
} */

/* @media screen and (min-width: 700px) and (max-width: 875px) {
  .card {
    box-shadow: -10px 0px 0px 0px #95bf41, 0px 3px 10px gray;
    padding: 12px;
    border-radius: 6px;
    margin: 5px;
    height: 30vh;
  }
} */

/* RESPONSIVE CARD */
@media (max-width: 767px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }

  .card {
    height: 26vh;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .column {
    float: left;
    width: 30%;
    padding: 0 5px;
  }

  .card {
    height: 28vh;
  }
}

/* Large devices (desktops, 992px and up) */
/* @media (min-width: 992px) {
  .column {
    float: left;
    width: 30%;
    padding: 0 5px;
  }

  .card {
    height: 15.5vh;
  }
} */
@media (min-width: 992px) {
  .column {
    float: left;
    width: 30%;
    padding: 0 5px;
  }

  .card {
    height: 26vh;
  }
}

/* @media (min-width: 1281px) and (max-width: 1319px) {
  .column {
    float: left;
    width: 30%;
    padding: 0 5px;
  }

  .card {
    height: 21vh; 
  }
} */

/* @media (min-width: 1320px) {
  .column {
    float: left;
    width: 30%;
    padding: 0 5px;
  }

  .card {
    height: 15vh; 
  }
} */

/* Style the counter cards */
.card {
  box-shadow: -10px 0px 0px 0px #95bf41, 0px 3px 10px gray;
  padding: 12px;
  border-radius: 6px;
  margin: 5px;
  /* height: 15.5vh;  */
}


/* Titre principal d'une filière */
.filiereTitle {
  color: #95bf41;
  font-size: 1.5rem;
}

/* Small title filière */
.smallFiliereTitle {
  color: #a7bbd8;
}

/* .toggleButton {
    position: absolute;
    right: 0;
    border: 1px solid lightgrey;
    background: url("./../../../../../assets/pictos/eyePassword.png") center/30px 30px
      no-repeat;
    border: none;
    min-height: 38px;
    min-width: 38px;
    cursor: pointer;
    opacity: 0.4;
  } */

/* Toggle Button */

.switchButton {
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-left: 68%;
}

.blocSwitchButton {
  float: left;
}

.blocSwitchButton div {
  display: block;
  padding: 5px;
}

/* color toggle */
.toggleActive {
  color: #a7bbd8;
  font-size: 1.7rem;
  font-weight: bold;
  margin-right: 12px;
  /* margin-top: -32px; */
}

.toggleDisable {
  color: #0a405e;
  font-size: 1.7rem;
  font-weight: bold;
  margin-right: 12px;
  /* margin-top: -32px; */
}


.checkButton[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.toggleButton {
  cursor: pointer;
  text-indent: -9999px;
  width: 55px;
  height: 18px;
  background: #97B6E3;
  display: block;
  border-radius: 40px;
  position: relative;
}

.toggleButton:after {
  content: '';
  position: absolute;
  top: 0px;
  left: 2px;
  width: 18px;
  height: 18px;
  background: #fff;
  border-radius: 40px;
  transition: 0.3s;
}

.checkButton:checked+label {
  background: #97B6E3;
}

.checkButton:checked+label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.toggleButton:active:after {
  width: 130px;
}