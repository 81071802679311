.actions {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.general {
    font-family: OpenSans-Bold, Arial, Helvetica, sans-serif !important;
    font-size: 1.8rem;
    text-transform: uppercase !important;
    margin-bottom: 30px;
}

.status {
    text-align: center;
}

.header {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 30px;
}


.statusUpdate {
    display: flex;
    position: relative;
}

.changeStatus {
    width: 100%;
    height: auto;
    position: absolute;
    top: 35px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0px 3px 1px rgba(50, 50, 93, 0.11);
    border: 1px solid transparent;
    cursor: pointer;
    font-family: OpenSans-Light;
}

.buttons {
    display: flex;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    flex-flow: wrap;
}

.alertButton {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    width: 205px;
    height: 60px;
    background-color: white;
    padding-left: 20px;
    padding-right: 5px;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.11);
}

.redAlertButton::before {
    content: '';
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: red;
    width: 10px;
    height: 100%;
    position: absolute;
    left: 0;
}

.blueAlertButton::before {
    content: '';
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: rgb(0, 0, 238);
    width: 10px;
    height: 100%;
    position: absolute;
    left: 0;
}
.greenAlertButton::before {
    content: '';
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #95c043;
    width: 10px;
    height: 100%;
    position: absolute;
    left: 0;
}
.grayAlertButton::before {
    content: '';
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: lightgray;
    width: 10px;
    height: 100%;
    position: absolute;
    left: 0;
}

.labelButton {
    font-family: OpenSans-Bold, Arial, Helvetica, sans-serif !important;
    font-size: 1.4rem;
    text-transform: uppercase !important;
}

.disabled {
    color: #C6C6C6;
}

.alertClosed {
    color: #C6C6C6;
    cursor: auto;
}

.correct {
    font-family: OpenSans, Arial, Helvetica, sans-serif !important;
    font-size: 1rem;
    text-decoration: underline;
    font-weight: bold;
    white-space: nowrap;
    position: absolute;
    right: 5px;
    bottom: 2px;
}

.resolved {
    font-family: OpenSans, Arial, Helvetica, sans-serif !important;
    font-size: 1rem;
    text-decoration: none;
    font-weight: bold;
    white-space: nowrap;
    position: absolute;
    right: 5px;
    bottom: 2px;
}

.historyTrigger {
    font-family: OpenSans, Arial, Helvetica, sans-serif ;
    text-transform: none;
    margin-top : 30px;
    cursor: pointer;
    display: inline-block;
    font-size: 1.5rem;
}

.retrackContainer {
    text-align: center;
}

.noError {
    font-family: OpenSans-Bold, Arial, Helvetica, sans-serif;
    color: red;
}

.retrackModal {
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    padding: 2rem;
    font-family: OpenSans-Bold, Arial, Helvetica, sans-serif;

}

.retrackButtonsContainer {
    display: flex;
    justify-content: space-around;
}

.retrackTitle{
    font-size: 2rem;
    font-weight: bold;
}

.retrackContent { 
    font-size: 1.5rem;
}

.modalContent{
    /* display: flex; */
    flex-direction: column;
    font-family: OpenSans-Bold;
    justify-content: center;
    align-items: center;
    /* height: 80%; */
    font-size: 1.4rem;
  }