.button {
    font-family: OpenSans-SemiBold, Arial, Helvetica, sans-serif !important;
    font-size: 1.4rem;
    text-transform: uppercase !important;
    border: 1px solid transparent;
    background-color: #95c043;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 10px;
    line-height: 1.4rem;
    text-align: center;
    box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.11);
  }
  
  .button:hover {
    background-color: white;
    color: #91c800;
  }

  
.disabled, .disabled:hover {
  border: 1px solid transparent;
  background-color: #d3d3d3;
  color: white;
  cursor: not-allowed;
}
