.error {
  font-family: PrintaniaLight, Arial, Helvetica, sans-serif;
  color: red;
}

.hint {
  font-family: PrintaniaSemiLight, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  color: #b2b2b2;
}

.required {
  margin-left: 2px;
}

.input {
  min-width: 5em !important;
  /* border: solid 1px #d3d3d3 !important; */
  text-align: left !important;
  min-height: 1em !important;
  width: 100%;
  padding: 10px;
  border-radius: 0;
}

/* .inputError {
  background-color: #fff6f6;
} */

.input::-webkit-input-placeholder {
  color: #bbbbbb !important;
}

.input:-moz-placeholder {
  /* Firefox 18- */
  color: #bbbbbb !important;
}

.input::-moz-placeholder {
  /* Firefox 19+ */
  color: #bbbbbb !important;
}

.input:-ms-input-placeholder {
  color: #bbbbbb !important;
}

.input:disabled {
  background-color: #ffffff !important;
}

.input:disabled::-webkit-input-placeholder {
  color: #e2e2e2 !important;
}

.input:disabled:-moz-placeholder {
  /* Firefox 18- */
  color: #e2e2e2 !important;
}

.input:disabled::-moz-placeholder {
  /* Firefox 19+ */
  color: #e2e2e2 !important;
}

.input:disabled:-ms-input-placeholder {
  color: #e2e2e2 !important;
}