.tableWrapper {
  height: 100%;
  overflow-y: auto;
  background-color: white;
  padding: 2rem;
  padding-top: 0;
}

.searchPicto {
  height: 15px;
}

.inputContainer {
  display: flex;
}

.input {
  border-radius: 150px;
  border: 2px solid #0a405d;
  width: calc(100% - 5px);
  background: transparent;
}

.min {
  background-color: #ffd11a !important;
  color: white !important;
}

.maj {
  background-color:  #F28705 !important;
  color: white !important;
}
