.tableWrapper {
  height: 100%;
  overflow-y: auto;
  background-color: white;
  padding: 2rem;
  padding-top: 0;
}

.searchPicto {
  height: 15px;
}

.sortPicto {
  width: 14px;
}

.sortPictoContainer {
  margin-right: 5px;
}

.headerOrder {
  display: flex;
}

.header {
  white-space: nowrap;
}

.headerLabel {

}

.checkboxContainer {

}

.checkbox {

}

.inputContainer {
  display: flex;
}

.input {
  border: none;
  border-bottom: 1px solid #0A405D;
  width: calc(100% - 30px);
  background: transparent;
}

.input:focus {
  outline: none;
}

.filterPicto {
  width: 14px;
}

.button {
  margin-left: 10px;
}

.criticalityPicto {
  width: 12px;
  margin-left: 5px;
}

.criticalityContainer {
  display: flex;
}

.criticalityLabel {
  height: 12px;
  /* line-height: 14px; */
}

.loader {
  background-color: white;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.semanticLoader {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  transform: none !important;
}