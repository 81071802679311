.layout {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
  background-size: cover;
}

.content {
  width: 50%;
  background-color: #f7f7f8;
  float: right;
  height: 100%;
  position: relative;
}

.language {
  position: absolute;
  right: 10px;
  top: 10px;
}