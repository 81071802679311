.error {
  font-family: PrintaniaLight, Arial, Helvetica, sans-serif;
  color: red;
}

.hint {
  font-family: PrintaniaSemiLight, Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.horizontal {
  display: flex;
  flex-direction: row;
}

.item {
  display: flex;
  align-items: center;
  min-width: 10rem;
  min-height: 1.4rem;
}

.horizontal .item {
  margin-right: 0.5rem;
}

.vertical {
  display: flex;
  flex-direction: column;
}

.vertical .item {
  margin-bottom: 0.5rem;
}

.centered {
  align-self: center;
}

.spaced {
  justify-content: space-between;
}

.richLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radioInputBtn {
  height: 16px;
  width: 16px;
  border: 1px solid #a3a3a3;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radioInputBtn .selected {
  height: 8px;
  width: 8px;
  margin: auto;
  background-color: black;
  border-radius: 4px;
}

.richLabelBtn {
  border-width: 0px;
  background-color: transparent;
  padding: 0px;
  font-family: inherit;
  font-size: inherit;
}
