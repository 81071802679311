.form {

}

.formContainer {
  width: 80%;
  margin-right: 10px;
}

.formLine {
  
}

.inputLabel {

}

.error {

}

.cancelActions {

}

.formActions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  max-height: 36px;
}

.navigationActions {
  display: flex;
  justify-content: space-between;
}

.backNavigation {
  margin-right: 10px;
}

.nextNavigation {

}