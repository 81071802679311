.wizard {
  display: flex;
  flex-direction: row;
}

.form {
  width: 70%;
  padding: 50px;
    background: white;
}

.track {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
  left: -20px;
}

.step {
  display: none;
}

.activeStep {
  display: block;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titlePictoContainer {

}

.titlePicto {
  height: 50px;
}

.titleLabel {
  font-size: 2rem;
}

.formWrapper {
  height: calc(90vh - 7rem - 190px);
  overflow-y: auto;
  position: relative;
}

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.5rem;
  font-family: OpenSans, Arial, Helvetica, sans-serif;
}

.activeContainer {

}

.doneContainer {
  
}

.todoContainer {
  
}

.editContainer {
  cursor: pointer;
}

.badge {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.activeBadge {
  background-color: #91c800;
  color: white;
}

.doneBadge {
  background-color: #0A405D;
}

.todoBadge {
  background-color: white;
  color: #c1c1c1
}

.editBadge {
  background-color: #0A405D;
}

.label {

}

.activeLabel {
  color:white;
}

.doneLabel {
  color: #f8f8f8;
  opacity: 0.4;
}

.todoLabel {
  color: #f8f8f8;
  opacity: 0.4;
}

.editLabel {
  color: #f8f8f8;
  opacity: 0.4;
}

.checkPicto, .editPicto {
  max-width: 20px;
  max-height: 20px;
}

.deleteContainer {
  display: flex;
  margin-top: 10px;
  cursor: pointer;
}

.trashPictoContainer {

}

.trashPicto {
  height: 20px;
}

.deleteLabel {

}

.blured {
  filter: blur(1px);
}