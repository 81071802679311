.filterWrapper {
  margin-bottom: 30px;
}

.tableWrapper {
  height: 55vh;
  overflow-y: scroll;
}

.errorFile {
  margin-bottom: 5%;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  min-height: 100px;
  background-color: #fff;
}

.globalFileContent {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
}

.errorFileContainer {
  padding: 10px;
  display: grid;
}

.title {
  font-size: 1.2rem;
  text-align: center;
  height: 25%;
  margin-bottom: 0 0.5rem 1.5rem 0.5rem;
  white-space: pre-wrap;
  font-family: OpenSans, Arial, Helvetica, sans-serif !important;
}

.clusterData {
  font-size: 1.2rem;
  text-align: center;
  flex-direction: column;
  display: flex;
  align-items: center;
  font-family: OpenSans-Bold, Arial, Helvetica, sans-serif !important;
  /* white-space: nowrap; */
  /* display: grid;
    grid-template-columns: 5fr 1fr;
    grid-gap: 10px;*/
}

.subcategory {
  margin-bottom: 10px;
}

.errorNumber {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuItem {
  background: #95c043 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  width: 125px;
  height: 31px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.action {
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ffffff;
  font-family: OpenSans-Bold, Arial, Helvetica, sans-serif !important;
  font-size: 1.4em;
  margin: auto;
}

.tags {
  padding: 3px 5px;
  margin: 10px;
  background: #bccbd1;
  border-radius: 5px;
  font-family: OpenSans-Bold, Arial, Helvetica, sans-serif !important;
  font-size: 1.2rem;
  color: #ffffff;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.event {
  font-family: OpenSans-Italic, Arial, Helvetica, sans-serif !important;
  word-break: break-word;
}

.string {
  font-family: OpenSans-Bold, Arial, Helvetica, sans-serif !important;
  font-size: 1.2rem;
}

.filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonWrapper,
.inputContainer {
  margin-right: 10px;
}

.input {
  box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.11);
  width: 320px;
}

.iconInput {
  color: #0a405d;
}

.select {
  border-radius: 10px !important;
}

.header {
  position: sticky;
}

.filterContent {
  display: flex;
}

.refreshContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10%;
  cursor: pointer;
}

.refresh {
  /* width : 42px; */
  width: 30px;
}

.alertGroupHeaderRow {
  border: 1px solid red;
}

.alertItem {
  font-family: OpenSans-Bold, Arial, Helvetica, sans-serif !important;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  min-height: 150px;
  padding: 10px;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 10px;
  margin: auto;
  margin-bottom: 30px !important;
  flex-wrap: nowrap;
}

.alertItemColumn {
  /* border: 1px solid red; */
  width: 150px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin: 0 10px;
  overflow-wrap: break-word;
}
.alertItemColumnSmall {
  /* border: 1px solid red; */
  width: 80px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin: 0 10px;
  overflow-wrap: break-word;
}

.alertItemColumnHeader {
  text-align: center;
  margin-bottom: 5px;
}

.alertItemColumnContent {
  text-align: center;
  margin: auto;
}

.alertButton {
  align-self: center;
}
